






























































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import UserService from "@/services/user.service";
import BaseVue from "@/core/BaseVue";
import { AuthenticationContext } from "vue-adal"; // This will be populated with a valid context after initialization
import User from "@/models/security/user.model";
import router from "../../router/index";
@Component({
  components: {},
})
export default class RecoverynPassword extends BaseVue {
  public userService = new UserService();
  public model: User = new User();
  public isLoading: boolean = false;
  public email: string = "";
  public password: string = "";
  public passwordConfirmed: string = "";
  public isCodeGenerateUsed: boolean = false;
  public messageError = "";
  loading = false;
  public codeVerificationSended = false;
  public recoveryUser: any = {};
  created() {
    let codeGenareted = this.$route.params.id;
    this.validatinGenerateCode(codeGenareted);
    if(this.user.id)
    this.$router.push("/home")
  }
   get user() {
    return this.$store.state.user;
  }
  
  changeUserPassword() {
    this.isLoading = true;
     let arr1 =  this.password.split("")
     let arr2 =  this.passwordConfirmed.split("")

    if(arr1.sort().join(',') === arr2.sort().join(',')){
    this.userService
      .changeUserPassword(this.recoveryUser.adid, this.password)
      .then(() => {
        this.operationSuccess();
        this.$router.push("/home");
      })
      .catch((x) => {
        this.messageError = x.response.data.error.message;
        this.operationFailed(x.response.data.error.message, 5000);
      })
      .finally(() => {
        this.isLoading = false;
      });
    }else{
      this.operationFailed("The password does't match");
      this.isLoading = false;
      return;
    }
  }
  
  @Watch("messageError")
  onMessageErrorChange() {
    this.operationFailed(this.messageError);
  }

  validatinGenerateCode(gerateCode: string) {
    this.userService.validatinGenerateCode(gerateCode).then((resp) => {
      debugger;
      if (!resp) {
        this.isCodeGenerateUsed = true;
      } else {
        this.recoveryUser = resp;
      }
    });
  }
}
